define("itarp-opportunity-designer/services/opportunity/wizard", ["exports", "@ember/service", "@ember/object", "@ember/application", "@ember/utils", "@glimmer/tracking", "itarp-opportunity-designer/config/environment", "@ember/array"], function (_exports, _service, _object, _application, _utils, _tracking, _environment, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let OpportunityWizardService = (_dec = (0, _service.inject)('appRouter'), _dec2 = (0, _service.inject)('ad-token-service'), _dec3 = (0, _service.inject)('opportunity/authorization'), (_class = class OpportunityWizardService extends _service.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "modal", _descriptor, this);

      _initializerDefineProperty(this, "transition", _descriptor2, this);

      _initializerDefineProperty(this, "appRouter", _descriptor3, this);

      _initializerDefineProperty(this, "auth", _descriptor4, this);

      _initializerDefineProperty(this, "authorization", _descriptor5, this);

      _initializerDefineProperty(this, "steps", _descriptor6, this);

      _defineProperty(this, "allSteps", [{
        name: 'job-details',
        icon: 'basics',
        label: 'Job Details',
        validate: true,
        roles: [],
        fields: ['hiringManager', 'jobTitle', 'internalId', 'description', 'skills', 'tools', 'industry', 'numberOfResources', 'skillLevels', 'educations', 'certifications']
      }, {
        name: 'work-arrangement',
        icon: 'arrangement',
        label: 'Work Arrangement',
        validate: true,
        roles: [],
        fields: ['jobType', 'timeCommitment', 'officeHours', 'outsideOfficeHours', 'flexible', 'startDate', 'endDate', 'targetDaysToFill', 'remunerations', 'minRate', 'maxRate', 'selectedPreferredLocation', 'openToRemote', 'travelRequirement']
      }, {
        name: 'application-requirement',
        icon: 'application',
        label: 'Application Form',
        validate: false,
        roles: [],
        fields: ['questionnaire']
      }, {
        name: 'scorecards',
        icon: 'scorecard',
        label: 'Add Scorecard',
        validate: false,
        roles: ['tod_client', 'site_admin', 'experfy_staff_user'],
        permission: {
          identifier: this.authorization.permissionIdentifier,
          action: [this.authorization.permissions.action.linkScorecard]
        },
        fields: []
      }, {
        name: 'workflows',
        icon: 'workflow',
        label: 'Add Workflow',
        validate: true,
        roles: ['site_admin', 'experfy_staff_user'],
        fields: ['workflowId'],
        permission: {
          identifier: this.authorization.permissionIdentifier,
          action: [this.authorization.permissions.action.linkApprovalWorkflow, this.authorization.permissions.action.linkRecruitingWorkflow]
        }
      }]);

      this.attachRouteChangeHandler();
    }

    get ownRoutes() {
      return [...this.allSteps.map(item => item.name), 'publishing-options'];
    }

    get currentRouteInfo() {
      return this.appRouter.currentRoute;
    }

    get currentRoute() {
      return this.currentRouteInfo.name;
    }

    get parentRoute() {
      return this.currentRouteInfo.parent.name;
    }

    get currentRouteInstance() {
      const owner = (0, _application.getOwner)(this);
      const prefix = this.currentRoute.split('.')[0];
      const routeFactoryName = this.currentRoute.replace(`${prefix}.`, 'route:');
      return owner.lookup(routeFactoryName);
    }

    get currentStep() {
      const name = this.currentRouteInfo.localName;
      return this.steps.findBy('name', name);
    }

    get currentStepNumber() {
      return this.steps.indexOf(this.currentStep) + 1;
    }

    get isFirstStep() {
      return this.steps.indexOf(this.currentStep) === 0;
    }

    get isLastStep() {
      const lastIndex = this.steps.length - 1;
      return this.steps.indexOf(this.currentStep) === lastIndex;
    }

    get nextStep() {
      const index = this.steps.indexOf(this.currentStep) + 1;
      if (this.isLastStep === false) return this.steps.objectAt(index);
    }

    get previousStep() {
      const index = this.steps.indexOf(this.currentStep) - 1;
      if (this.isFirstStep === false) return this.steps.objectAt(index);
    }

    get totalSteps() {
      return this.steps.length;
    }

    get previousRoute() {
      if (this.isFirstStep === false) {
        return this.parentRoute.concat('.').concat(this.previousStep.name);
      }
    }

    get nextRoute() {
      if (this.isLastStep === false) {
        return this.parentRoute.concat('.').concat(this.nextStep.name);
      }
    }

    get publishRoute() {
      return _environment.default.engineName.concat('.publishing-options');
    }

    attachRouteChangeHandler() {
      this.appRouter.on('routeWillChange', transition => {
        const {
          to,
          from
        } = transition;
        if (!from && !to) return;
        const currentDestintion = (from === null || from === void 0 ? void 0 : from.localName) === (to === null || to === void 0 ? void 0 : to.localName);
        const ownOrigin = from ? this.ownRoutes.includes(from.localName) : false;
        const processed = transition.data.processed;

        if (ownOrigin && !currentDestintion && !processed) {
          this.transition = transition;
          this.checkUnsavedChanges();
        }
      });
    }

    registerModalAPI(modal) {
      this.modal = modal;
    }

    checkUnsavedChanges() {
      this.transition.data.processed = true;
      if (this.hasUnsavedChanges()) this.promptCancelConfirmation();
    }

    hasUnsavedChanges() {
      const route = this.currentRouteInstance;
      const model = route === null || route === void 0 ? void 0 : route.currentModel;
      if (!model) return false;

      if ((0, _utils.typeOf)(model) === 'object') {
        const values = Object.values(model);
        return isAnyListItemDirty(values);
      }

      return opportunityIsDirty(model);
    }

    promptCancelConfirmation() {
      this.transition.abort();
      this.modal.toggle();
    }

    confirmCancelation() {
      this.transition.retry().then(() => {
        this.transition = null;
      });
    }

    abortCancelation() {
      this.transition = null;
    }

    assureModelExists(transition) {
      const parent = transition.to.parent;
      if (parent.localName === 'edit-opportunity') return;
      const opportunity = transition.resolvedModels[parent.name];
      if (opportunity.id) return;
      const firstStep = this.steps.firstObject || this.allSteps.firstObject;
      const firstRouteName = parent === null || parent === void 0 ? void 0 : parent.name.concat('.').concat(firstStep === null || firstStep === void 0 ? void 0 : firstStep.name);
      transition.abort();
      this.appRouter.transitionTo(firstRouteName);
    }

    async setupSteps() {
      const stepsByRole = this.allSteps.filter(this.filterStepsByRole);
      const unrestrictedSteps = stepsByRole.filter(s => !s.permission);
      this.steps = unrestrictedSteps;
      const authorizedSteps = await Promise.all(stepsByRole.map(this.filterStepsByPermission));
      this.steps = await stepsByRole.filter((step, index) => authorizedSteps[index]);
      return this.steps;
    }

    filterStepsByRole(step) {
      if (step.roles.length === 0) return true;
      return step.roles.includes(this.auth.tokenInformation.extension_user_type);
    }

    async filterStepsByPermission(step) {
      if (!step.permission) return true;
      const action = step.permission.action;
      if (!(0, _array.isArray)(action)) return this.authorization.isPermitted(action);
      return Promise.all(action.map(this.authorization.isPermitted)).then(result => result.some(isPermitted => isPermitted));
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modal", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "transition", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "appRouter", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "auth", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "authorization", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "steps", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "attachRouteChangeHandler", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "attachRouteChangeHandler"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "registerModalAPI", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "registerModalAPI"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "checkUnsavedChanges", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "checkUnsavedChanges"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hasUnsavedChanges", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "hasUnsavedChanges"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "promptCancelConfirmation", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "promptCancelConfirmation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "confirmCancelation", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "confirmCancelation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "abortCancelation", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "abortCancelation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "assureModelExists", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "assureModelExists"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupSteps", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setupSteps"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterStepsByRole", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "filterStepsByRole"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterStepsByPermission", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "filterStepsByPermission"), _class.prototype)), _class));
  _exports.default = OpportunityWizardService;

  function isOpportunity(item) {
    if (!item || !item.constructor) return false;
    const opportunity = 'opportunity';
    return item.constructor.modelName === opportunity;
  }

  function changedAttributes(item) {
    const attributes = item.changedAttributes();
    return Object.keys(attributes);
  }

  function opportunityIsDirty(record) {
    const attributes = changedAttributes(record);
    return record.isNew ? !!attributes.without('hiringManager').length : !!attributes.length;
  }

  function isAnyListItemDirty(list) {
    const initialResult = false;
    return list.reduce((anyIsDirty, currentItem) => {
      if (anyIsDirty) return anyIsDirty;

      if ((0, _utils.typeOf)(currentItem) === 'array') {
        return isAnyListItemDirty(currentItem);
      }

      if (isOpportunity(currentItem)) {
        return opportunityIsDirty(currentItem);
      }

      return (currentItem === null || currentItem === void 0 ? void 0 : currentItem.hasDirtyAttributes) === true;
    }, initialResult);
  }
});