define("itarp-opportunity-designer/controllers/edit-opportunity/job-details", ["exports", "itarp-opportunity-designer/controllers/opportunity/job-details"], function (_exports, _jobDetails) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class EditOpportunityJobDetailsController extends _jobDetails.default {}

  _exports.default = EditOpportunityJobDetailsController;
});