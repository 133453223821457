define("itarp-opportunity-designer/serializers/opportunity", ["exports", "itarp-shared-assets-addon/serializers/data", "@ember/utils"], function (_exports, _data, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class OpportunitySerializer extends _data.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "attrs", {
        questions: {
          serialize: false
        }
      });
    }

    serialize() {
      const json = super.serialize(...arguments);
      const page = json.opportunities.page;

      for (const attribute in json.opportunities) {
        const value = json.opportunities[attribute];
        const emptyValues = [null, undefined, ''];

        if (emptyValues.includes(value)) {
          delete json.opportunities[attribute];
        }
      }

      if (page) {
        return this.withPage(json);
      }

      return json;
    }

    withPage(json) {
      const page = {
        slug: json.opportunities.page
      };
      delete json.opportunities.page;
      return { ...json,
        page
      };
    }

    serializeHasMany(snapshot, json, relationship) {
      const relationships = {
        questionnaire: this.serializeQuestions,
        cloudId: this.serializeClouds,
        practiceAreas: this.serializePracticeAreas
      };
      const key = relationship.key;

      if (relationships[key]) {
        relationships[key](snapshot, json);
      } else {
        return super.serializeHasMany(...arguments);
      }
    }

    normalize(modelClass, payload) {
      const questions = payload.questions;
      const clouds = payload.cloud_id;
      const practiceAreas = payload.practice_areas;
      const result = super.normalize(...arguments);

      if (clouds !== null && clouds !== void 0 && clouds.length) {
        result.data.relationships.cloudId = this.createCloudsReference(this.store, clouds);
      }

      if (practiceAreas !== null && practiceAreas !== void 0 && practiceAreas.length) {
        result.data.relationships.practiceAreas = this.createPracticeAreasReference(this.store, practiceAreas);
      }

      if (questions !== null && questions !== void 0 && questions.length) {
        result.data.relationships.questionnaire = this.createQuestionsReference(this.store, questions, result.data.id);
      }

      return result;
    }

    serializeClouds(snapshot, json) {
      const clouds = snapshot.hasMany('cloudId');

      if ((clouds === null || clouds === void 0 ? void 0 : clouds.length) > 0) {
        json.cloud_id = clouds.mapBy('id');
      }
    }

    serializePracticeAreas(snapshot, json) {
      const practiceAreas = snapshot.hasMany('practiceAreas');

      if ((practiceAreas === null || practiceAreas === void 0 ? void 0 : practiceAreas.length) > 0) {
        json.practice_areas = practiceAreas.mapBy('id');
      }
    }

    serializeQuestions(snapshot, json) {
      const questionnaire = snapshot.hasMany('questionnaire');
      const questions = snapshot.record.questions;

      if ((questionnaire === null || questionnaire === void 0 ? void 0 : questionnaire.length) > 0) {
        json.questions = questionnaire.map(question => {
          const questionnaire_id = question.id;
          const order = question.record.position;
          const existing = questions.findBy('questionnaire_id', questionnaire_id);
          if (existing) return { ...existing,
            order
          };
          return {
            questionnaire_id,
            order
          };
        });
      }
    }

    createQuestionsReference(store, questions, id) {
      const type = 'questionnaire';
      const adapter = store.adapterFor(type);
      const collection = adapter.pathForType(type);
      const endpoint = `${adapter.namespace}/${collection}`;
      const links = {
        related: `${endpoint}?filter[resource_type]=opportunity&filter[resource_id]=${id}&per_page=infinity`
      };
      const data = questions.sortBy('order').map(({
        questionnaire_id: id
      }) => ({
        id,
        type
      }));
      return {
        data,
        links
      };
    }

    createReference(store, refIds, type, collection) {
      const adapter = store.adapterFor('opportunity');
      const ids = Array.isArray(refIds) ? refIds : [refIds];
      const query = ids.map(id => `advance_filter[id][$in][]=${id}`).join('&');
      return {
        data: ids.map(id => ({
          id,
          type
        })),
        links: {
          related: `${adapter.namespace}/${collection}?${query}&per_page=infinity`
        }
      };
    }

    createCloudsReference(store, clouds) {
      const type = 'talent-cloud';
      const collection = 'talent_clouds';
      return this.createReference(store, clouds, type, collection);
    }

    createPracticeAreasReference(store, practiceAreas) {
      const type = 'practice-area';
      const collection = 'practice_areas';
      return this.createReference(store, practiceAreas, type, collection);
    }

  }

  _exports.default = OpportunitySerializer;
});