define("itarp-opportunity-designer/controllers/opportunity/work-arrangement", ["exports", "@ember/controller", "@ember/object", "@glimmer/tracking"], function (_exports, _controller, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let OpportunityWorkArrangementController = (_class = class OpportunityWorkArrangementController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "jobTypes", _descriptor, this);

      _defineProperty(this, "remunerationTypes", ['Hourly Rate', 'Fixed Price Project', 'Annual Salary', 'Monthly Salary']);

      _defineProperty(this, "sliderOptions", Object.freeze({
        type: 'sleek',
        hideValue: true,
        range: {
          min: 0,
          max: 100
        }
      }));

      _initializerDefineProperty(this, "targetDaysToFillError", _descriptor2, this);

      _defineProperty(this, "travelRequirements", ['1-25%', '26-50%', '51-75%', '76-100%']);
    }

    setSlider(e) {
      if (isNaN(e.target.value) || e.target.value > 100 || e.target.value < 0) return;
      this.project.timeCommitment = parseInt(e.target.value);
    }

    sliderChange(oldValue, newValue) {
      if (oldValue != newValue) {
        this.project.timeCommitment = newValue;
      }

      return;
    }

    get dateToday() {
      const today = new Date();
      const day = today.getDate();
      const month = today.getMonth() + 1;
      const year = today.getFullYear();
      return moment.utc(`${day}-${month}-${year}`, 'DD-MM-YYYY').toDate();
    }

    get minEndDate() {
      const {
        startDate
      } = this.project;
      return startDate ? startDate : this.dateToday;
    }

    get maxTargetDaysToFill() {
      const {
        startDate
      } = this.project;
      const maxDays = startDate ? moment(startDate).diff(this.dateToday, 'days') : 0;
      return maxDays > 0 ? maxDays : 0;
    }

    get isErrorsNotEmpty() {
      return this._isErrorsNotEmpty;
    }

    get isTimeCommitmentGreaterThan40() {
      return this.project.timeCommitment > 40;
    }

    get locationConfig() {
      const project = this.project;
      return {
        attributes: {
          get country() {
            return project.preferredCountry;
          },

          set country(val) {
            project.set('preferredCountry', val);
          },

          get state() {
            return project.preferredState;
          },

          set state(val) {
            project.set('preferredState', val);
          },

          get city() {
            return project.preferredCity;
          },

          set city(val) {
            project.set('preferredCity', val);
          },

          get zipcode() {
            return project.preferredZipCode;
          },

          set zipcode(val) {
            project.set('preferredZipCode', val);
          },

          get idealCandidateLocation() {
            return project.idealCandidateLocation;
          },

          set idealCandidateLocation(val) {
            project.set('idealCandidateLocation', val);
          },

          get locationCoordinates() {
            return project.locationCoordinates;
          },

          set locationCoordinates(val) {
            project.set('locationCoordinates', val);
          }

        }
      };
    }

    toggleHours(type, e) {
      this.project['officeHours'] = false;
      this.project['outsideOfficeHours'] = false;
      this.project['flexible'] = false;
      this.project[type] = e.target.value === 'true';
    }

    togglePreferredLocation() {
      const {
        selectedPreferredLocation
      } = this.project;
      this.project.selectedPreferredLocation = !selectedPreferredLocation;

      if (!this.project.selectedPreferredLocation) {
        this.project.preferredCountry = null;
        this.project.preferredState = null;
        this.project.preferredCity = null;
        this.project.preferredZipCode = null;
        this.project.idealCandidateLocation = null;
      }
    }

    setRemoteOnly(val) {
      this.project.openToRemote = val;
    }

    changeDate(type, d) {
      const day = d.date.getDate();
      const month = d.date.getMonth() + 1;
      const year = d.date.getFullYear();
      const newDate = moment.utc(`${day}-${month}-${year}`, 'DD-MM-YYYY').toDate();
      (0, _object.set)(this.project, type, newDate); // check it is a past date

      if (moment(newDate).diff(moment(), 'days') < 0) {
        this.project.errors.add(type, (type === 'startDate' ? 'Start date' : 'End date') + ' cannot be a past date');
      } else {
        this.project.errors.remove(type);
      }

      if (type === 'startDate' && this.project.targetDaysToFill > 0) {
        if (this.project.targetDaysToFill > this.maxTargetDaysToFill) {
          this.project.targetDaysToFill = this.maxTargetDaysToFill;
          this.targetDaysToFillError = `Target days to fill cannot be more than days between start date and today (${this.maxTargetDaysToFill} ${this.maxTargetDaysToFill === 1 ? 'day' : 'days'})`;
        } else {
          this.targetDaysToFillError = null;
        }
      }
    }

    handleDateChange(attribute, {
      key,
      target: {
        selectionStart,
        value
      }
    }) {
      const removeKeyNames = ['Backspace', 'Clear', 'Delete'];
      const cursorIsAtEnd = selectionStart === value.length;
      const cursorIsAtStart = selectionStart === 0;
      const atDeletePosition = cursorIsAtStart || cursorIsAtEnd;
      const pressedRemoveKey = removeKeyNames.includes(key);

      if (atDeletePosition && pressedRemoveKey) {
        this.project[attribute] = null;
      } else {
        this.changeDate(attribute, {
          date: this.project[attribute]
        });
      }
    }

    setTargetDaysToFill(e) {
      const value = Number(e.target.value);

      if (value > this.maxTargetDaysToFill) {
        this.project.targetDaysToFill = this.maxTargetDaysToFill;
        this.targetDaysToFillError = `Target days to fill cannot be more than days between start date and today (${this.maxTargetDaysToFill} ${this.maxTargetDaysToFill === 1 ? 'day' : 'days'})`;
      } else {
        this.project.targetDaysToFill = value;
        this.targetDaysToFillError = null;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "jobTypes", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return ['Contract', 'Full-time Permanent', 'Part-time Permanent', 'Flexible', 'Internship'];
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "targetDaysToFillError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setSlider", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setSlider"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sliderChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "sliderChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleHours", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleHours"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "togglePreferredLocation", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "togglePreferredLocation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setRemoteOnly", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setRemoteOnly"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeDate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleDateChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleDateChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setTargetDaysToFill", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setTargetDaysToFill"), _class.prototype)), _class);
  _exports.default = OpportunityWorkArrangementController;
});