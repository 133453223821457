define("itarp-workflow-designer/controllers/workflow/show", ["exports", "@ember/controller", "@ember/object", "@glimmer/tracking", "@ember/string"], function (_exports, _controller, _object, _tracking, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let WorkflowShowController = (_class = class WorkflowShowController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "selectedStage", _descriptor, this);

      _initializerDefineProperty(this, "displayButtons", _descriptor2, this);
    }

    get uiActionsList() {
      const actions = [];

      if (this.selectedStage) {
        var _this$selectedStage$g;

        (_this$selectedStage$g = this.selectedStage.get('actions')) === null || _this$selectedStage$g === void 0 ? void 0 : _this$selectedStage$g.forEach(action => {
          if (action.actionType === 'ui_action') {
            actions.push(action);
          }
        });
      }

      return actions;
    }

    get displayStageName() {
      if (this.selectedStage) {
        var _this$selectedStage;

        return (0, _string.capitalize)((_this$selectedStage = this.selectedStage) === null || _this$selectedStage === void 0 ? void 0 : _this$selectedStage.get('name').toLowerCase()) + ',';
      }

      return '';
    }

    toggleStage(stage) {
      this.selectedStage = stage;
      this.displayButtons = false;
    }

    toggleButtons() {
      this.displayButtons = !this.displayButtons;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "selectedStage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "displayButtons", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleStage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleStage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleButtons", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleButtons"), _class.prototype)), _class);
  _exports.default = WorkflowShowController;
});