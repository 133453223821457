define("itarp-opportunity-designer/controllers/details-public/overview", ["exports", "@ember/controller", "itarp-components/utils/constants/questions"], function (_exports, _controller, _questions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DetailsPublicOverviewController extends _controller.default {
    get questionTypes() {
      return _questions.TYPES;
    }

    get status() {
      return this.parentController.opportunityStatus;
    }

    get statusClass() {
      return this.parentController.statusClass;
    }

  }

  _exports.default = DetailsPublicOverviewController;
});